import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from '../../../../components/layout/Modal/Modal'

export default function OrderPublishConfirmationModal({
  onOrderPublishConfirmed,
  opened,
  setOpened,
  data: {
    buySellTabsIndex,
    selectedExchangeAsset,
    selectedOtcAsset,
    userSelectedTradeRate,
    amountOfSelectedExchangeAsset,
    limitMin,
    limitMax,
    commissionAmount,
    selectedPaymentDuration,
    selectedPaymentRequisites,
    isKYCPassed,
    selectedMinDaysSinceRegistration,
    selectedMinOrdersRequired,
    selectedMinOrderCompletionRate,
  },
}) {
  const { t } = useTranslation()

  const isBuying = buySellTabsIndex === 0 ? 'Buy' : 'Sell'
  const isBuyingColor = buySellTabsIndex === 0 ? 'colorSecondary500' : 'colorTertiary500'

  return (
    <Modal opened={opened} blockStyle={'modal600'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='modal__section'>
          <div className='wysiwyg'>
            <h2>{t('ConfirmationOfPublication')}</h2>
          </div>
          <div className='wysiwyg text-center colorTertiary500'>
            <p>
              <strong>{t('AfterPublication')}</strong>
            </p>
          </div>
          <div className='data'>
            <div className='data__list data__list_second data__list_column text-center'>
              <div className='row'>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Type')}</span>
                    </div>
                    <div className='data__main'>
                      <span className={`data__text  ${isBuyingColor}`}> {t(isBuying)}</span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Active')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{selectedExchangeAsset}</span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Currency')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{selectedOtcAsset}</span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Rate')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {userSelectedTradeRate} {selectedOtcAsset}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_4 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Available')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {amountOfSelectedExchangeAsset} {selectedExchangeAsset}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_4 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Limits')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {limitMin} - {limitMax} {selectedExchangeAsset}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_4 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Commission')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {commissionAmount} {selectedExchangeAsset}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__section'>
          <div className='data'>
            <div className='data__list data__list_row'>
              <div className='row'>
                <div className='col col_12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('RequirementsForTheCounterparty')}</span>
                    </div>
                    <div className='data__main'>
                      {isKYCPassed && (
                        <div className='data__item'>
                          <span className='data__text'>{t('KYCPassed')}</span>
                        </div>
                      )}
                      {selectedMinDaysSinceRegistration !== 0 && (
                        <div className='data__item'>
                          <span className='data__text'>{`${t('DaysHavePassedSecondary')}: ${t('P2PDays', {
                            days: selectedMinDaysSinceRegistration,
                          })}`}</span>
                        </div>
                      )}
                      {selectedMinOrdersRequired !== 0 && (
                        <div className='data__item'>
                          <span className='data__text'>{`${t(
                            'ExecutedDealsWithin30Days',
                          )}: ${selectedMinOrdersRequired}`}</span>
                        </div>
                      )}
                      {selectedMinOrderCompletionRate !== 0 && (
                        <div className='data__item'>
                          <span className='data__text'>{`${t(
                            '30DayCompletion',
                          )}: ${selectedMinOrderCompletionRate}`}</span>
                        </div>
                      )}
                      {!isKYCPassed &&
                        selectedMinDaysSinceRegistration === 0 &&
                        selectedMinOrdersRequired === 0 &&
                        selectedMinOrderCompletionRate === 0 && <div>--</div>}
                    </div>
                  </div>
                </div>
                <div className='col col_12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('PaymentMethods')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {selectedPaymentRequisites &&
                          selectedPaymentRequisites
                            .filter((paymentMethod) => paymentMethod.selected)
                            .map((method) => method.paymentSystem.name)
                            .join(', ')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('PaymentTerm')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{t('P2PMins', { mins: selectedPaymentDuration })}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn btn_revert w-100'
                      onClick={() => {
                        setOpened(false)
                      }}
                    >
                      <span className='btn__text'>{t('Cancel')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100' onClick={onOrderPublishConfirmed}>
                      <span className='btn__text'>{t('ConfirmPublication')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
