import React from 'react'
import Button from '../../../components/form/Button/Button'
import ProgressIcons from './ProgressIcons'
import useModalState from './Modals/useModalState'
import CommonModals from './Modals/CommonsMadals'

const ProgressAppealInfo = ({ t, deal, onProgressAppealInfo, isDisabledBtnCancel }) => {
  const { modals, openModal, closeModal } = useModalState()
  const { isDealCreator, dealUserId, order, status } = deal || {}
  const dealAppeals = deal?.dealAppeals || {}
  const dealAppealLast = dealAppeals && dealAppeals.length > 0 ? dealAppeals[dealAppeals.length - 1] : []
  const createAppealUserName =
    dealAppealLast?.creatorUserId === dealUserId ? deal.userNickname : deal.order.userNickname

  const isCreatorAppeal =
    (isDealCreator && dealAppealLast?.creatorUserId === dealUserId) ||
    (!isDealCreator && dealAppealLast?.creatorUserId === order?.userId)

  return (
    <React.Fragment>
      <div className='wrapper'>
        <div className='p2p__info info'>
          <div className='info__media'>
            <ProgressIcons name='exclamationAppealIco' />
          </div>
          <div className='info__main'>
            <div className='info__title title'>
              <span className='title__text'>{t('AppealIsPending')}</span>
            </div>
            <div className='info__data data'>
              <ul>
                <li>
                  {t('Submitted')}: {createAppealUserName}
                </li>
                <li>
                  {t('ReasonForAppeal')}: {t('P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins')}
                </li>
              </ul>
            </div>
            <div className='info__help help'>
              <ProgressIcons name='exclamationAppealSmallIco' />
              <span className='head__title'>{t('ForAppealRelatedQuestions')}</span>
            </div>
          </div>
          <div className='info__footer'>
            {isCreatorAppeal && (
              <Button
                buttonCallback={() => openModal('isCancelAppealWarningModalOpen')}
                addClasses={['btn btn_revert']}
                text={t('CancelAppeal')}
                disabled={isDisabledBtnCancel}
              />
            )}
          </div>
        </div>
      </div>
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        deal={deal}
        onConfirmCommonModals={(actionType, payload) => {
          onProgressAppealInfo(actionType, payload)
        }}
      />
    </React.Fragment>
  )
}

export default ProgressAppealInfo
