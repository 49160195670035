import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'api.js'
import i18n from 'i18next'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FillingDataNewsActicle from './FillingDataNewsActicle.jsx'

export default function CreateOrEditNewsArticle({ currencyListingCode, editNewsArticle, onUpdate, onBack }) {
  const { t } = useTranslation()
  const history = useHistory()

  const [newsArticle, setNewsArticle] = useState({
    newsItemId: null,
    selectedImageFile: null,
    imageId: null,
    title: '',
    subtitle: '',
    content: '',
    currencyListingCode: currencyListingCode,
    imageUrl: null,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true)
  const [isChanged, setIsChanged] = useState(false)

  const handleInputChange = (name, value) => {
    setNewsArticle((prevData) => {
      const newValue = value ?? ''
      if (prevData[name] === newValue) {
        return prevData
      }
      setIsChanged(true)
      return {
        ...prevData,
        [name]: newValue,
      }
    })
  }

  const fieldsValidation = () => {
    const requiredFields = ['title', 'subtitle', 'content']
    const isValid = requiredFields.every((field) => newsArticle[field] && newsArticle[field].trim() !== '') && isChanged

    setIsConfirmBtnDisabled(!isValid || (newsArticle.selectedImageFile === null && !newsArticle.imageId))
  }

  const handleClickConfirm = async () => {
    uploadImagesAndCreateNews()
  }
  const handleClickBack = async () => {
    onBack()
  }

  const createNewsDto = (imageId) => {
    const newDto = {
      currencyListingCode: currencyListingCode,
      title: newsArticle.title,
      preview: newsArticle.subtitle,
      body: newsArticle.content,
      ...(imageId && { imageId }),
      ...(newsArticle.newsItemId && { newsItemId: newsArticle.newsItemId }),
    }

    return newDto
  }

  const createUploadImage = async () => {
    const uploadImage = {
      Extension: newsArticle.selectedImageFile.name.split('.').pop(),
      Data: await fileToByteArray(newsArticle.selectedImageFile),
      ...(newsArticle.newsItemId && { newsId: newsArticle.newsItemId }),
    }

    return uploadImage
  }

  const uploadImagesAndCreateNews = async () => {
    setIsLoading(true)
    try {
      let imageId = newsArticle.imageId
      if (newsArticle.selectedImageFile) {
        const uploadImage = await createUploadImage()
        imageId = await api.uploadCurrencyListingImage(uploadImage)
      }
      const newsDto = createNewsDto(imageId)

      newsDto.newsItemId ? await api.updateCurrencyListingNews(newsDto) : await createNewsArticle(newsDto)
      toast.success(t('ActionWasSuccessful'))
      onUpdate()
    } catch (error) {
      toast.error(t('ErrorTryLater'))
      console.error('Error during upload or listing creation:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const createNewsArticle = async (newsDto) => {
    try {
      var data = await api.createCurrencyListingNews(newsDto)
    } catch (error) {
      console.error('Error updating currency listing:', error)
    }
  }

  const fileToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(Array.from(new Uint8Array(event.target.result)))
      }
      reader.onerror = (error) => reject(error)
      reader.readAsArrayBuffer(file)
    })
  }
  useEffect(() => {
    fieldsValidation()
  }, [newsArticle])

  useEffect(() => {
    if (!editNewsArticle) return

    setNewsArticle((prevData) => ({
      ...prevData,
      currencyListingCode: currencyListingCode,
    }))

    if (!editNewsArticle.newsVersions || editNewsArticle.newsVersions.length === 0) return

    const news = editNewsArticle.newsVersions[editNewsArticle.newsVersions.length - 1]
    setNewsArticle((prevData) => ({
      ...prevData,
      newsItemId: editNewsArticle.id || null,
      title: news.title || '',
      subtitle: news.preview || '',
      content: news.body || '',
      imageId: news.imageId || null,
    }))
  }, [editNewsArticle])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='layout_Tokens'>
      <main className='layout__main'>
        <section className='layout__section section'>
          <div className='container'>
            <div className='section__header'>
              <h2 className='section__title'>{t('TokenCreateUpdate')}</h2>
            </div>
            <div className='section__main'>
              <div className='layout__tokens tokens'>
                <div className='tokens__form form'>
                  <aside className='tokens__sidebar sidebar'>
                    <div className='sidebar__footer'>
                      <div className='form__item'>
                        <div className='form__main'>
                          <button className='btn btn_secondary' disabled={isLoading} onClick={handleClickBack}>
                            <span className='btn__text'>{t('Back')}</span>
                          </button>
                          <button
                            className='btn'
                            disabled={isConfirmBtnDisabled || isLoading}
                            onClick={handleClickConfirm}
                          >
                            <span className='btn__text'>{isLoading ? t('TokenLoading') : t('TokenPublish')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </aside>
                  <main className='tokens__main'>
                    <FillingDataNewsActicle t={t} onInputChange={handleInputChange} newsArticle={newsArticle} />
                  </main>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
