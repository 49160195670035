import style from '../style/TokenPage.module.scss'
import { cssClass } from 'helpers/helper'
import TokenIcons from '../TokenData/TokenIcons'
import { FormatedDate, Tabs } from '../TokenData/TokenData'
import React, { useEffect, useState } from 'react'
import api from 'api'
import NewsArticlesMoreDetail from './NewsArticlesMoreDetail'
import NewsArticle from './NewsArticle'
import i18n from 'i18next'
import { useAuth } from 'auth/auth'
import { useHistory } from 'react-router-dom'
import CreateOrEditNewsArticle from './CreateOrUpdateNewsArticle/CreateOrEditNewsArticle'

const NewsArticlesTab = ({ t, code, isAuthor, selectTab, setSelectTab }) => {
  let auth = useAuth()
  const history = useHistory()

  const [newsArticles, setNewsArticles] = useState(null)
  const [newsArticlesCommentsCount, setNewsArticlesCommentsCount] = useState([])
  const [localDisabledState, setLocalDisabledState] = useState(false)
  const [selectedNews, setSelectedNews] = useState(null)
  const [selectedNewsIndex, setSelectedNewsIndex] = useState(null)
  const [editNewsArticle, setEditNewsArticle] = useState(null)

  const fetchNewsArticles = (ignore = false) => {
    api
      .fetchCurrencyListingNewsList(code)
      .then((data) => {
        if (ignore || data.length === 0) return
        // const sortedNews = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setNewsArticles(data)
      })
      .catch((error) => {
        if (ignore) return
        console.error('Error fetchCurrencyListingNews', error)
      })
  }
  const fetchCommentCount = () => {
    const promises = newsArticles.map((news) => {
      const newsId = news?.id
      return api.fetchCurrencyListingNewsCommentCount(newsId).then((count) => ({
        newsId,
        count,
      }))
    })

    Promise.all(promises)
      .then((newsArticlesCommentsCount) => {
        setNewsArticlesCommentsCount(newsArticlesCommentsCount)
      })
      .catch((error) => {
        console.error('Error fetchCommentCount', error)
      })
  }

  const handleUpdateCommentsCountForNews = (newsId) => {
    api
      .fetchCurrencyListingNewsCommentCount(newsId)
      .then((data) => {
        setNewsArticlesCommentsCount((prevNewsArticlesCommentsCount) =>
          prevNewsArticlesCommentsCount.map((item) => {
            if (item.newsId === newsId) {
              return { ...item, count: count }
            }
            return item
          }),
        )
      })
      .catch((error) => {
        console.error('Error handleUpdateCommentsCountForNews', error)
      })
  }
  const processNewsArticle = (news = null) => {
    if (!news) {
      setEditNewsArticle({ currencuListingCode: code })
      return
    }
    setEditNewsArticle(news)
  }

  const handleLikeUpdateForNews = (newsId) => {
    if (!auth.isSignedIn) {
      goToRegistrationPage()
      return
    }

    if (localDisabledState || !newsId) return null
    setLocalDisabledState(true)
    api
      .updateLikeForCurrencyListingNews(newsId)
      .then((data) => {
        setNewsArticles((prevNewsArticles) =>
          prevNewsArticles.map((news) => {
            if (news?.id === newsId) {
              return { ...news, newsLikes: data }
            }
            return news
          }),
        )
      })
      .catch((error) => {
        console.error('Error during updateLikeForNews', error)
      })
      .finally(() => {
        setLocalDisabledState(false)
      })
  }
  const goToRegistrationPage = () => {
    history.push(`/${i18n.language}/register`)
  }

  useEffect(() => {
    let ignore = false
    fetchNewsArticles(ignore)
    return () => {
      ignore = true
    }
  }, [code])

  useEffect(() => {
    let ignore = false
    if (!newsArticles || newsArticles.length === 0 || newsArticlesCommentsCount.length !== 0) return
    fetchCommentCount(ignore)
    return () => {
      ignore = true
    }
  }, [newsArticles])

  useEffect(() => {
    if (!newsArticles || !selectedNews) return

    const updatedSelectedNews = newsArticles.find((news) => news.id === selectedNews.id)

    if (updatedSelectedNews) {
      setSelectedNews((prevSelectedNews) => ({
        ...prevSelectedNews,
        ...updatedSelectedNews,
        NewsLikes: updatedSelectedNews.NewsLikes,
      }))
    }
  }, [newsArticles])

  useEffect(() => {
    if (!editNewsArticle) return
    setSelectTab(Tabs.CreateOrEditNewsArticle)
  }, [editNewsArticle])

  return (
    <React.Fragment>
      {selectTab === Tabs.NewsArticles && (
        <div className={cssClass(style.tabs__item)}>
          <div className={cssClass(style.container, style.container_secondary)}>
            <div className={cssClass(style.project__articles, style.articles)}>
              {!selectedNews && isAuthor && (
                <div className={cssClass(style.project_update)}>
                  <div className={cssClass(style.project_update__text)}>{t('TokenCreateAPostUpdating')}</div>
                  <button className={cssClass(style.project_update__button)} onClick={() => processNewsArticle()}>
                    {t('Create')}
                  </button>
                </div>
              )}

              <div className={cssClass(style.articles__list)}>
                {!selectedNews &&
                  newsArticles &&
                  newsArticles.map((news, index) => (
                    <NewsArticle
                      key={news?.id}
                      t={t}
                      index={index + 1}
                      newsArticle={news}
                      setSelectedNews={(selectedNews) => {
                        setSelectedNews(selectedNews)
                        setSelectedNewsIndex(index)
                      }}
                      onLikeUpdateForNews={handleLikeUpdateForNews}
                      newsArticleCommentsCount={
                        newsArticlesCommentsCount.find((item) => item.newsId === news?.id)?.count
                      }
                      isAuthor={isAuthor}
                      isEditNews={() => processNewsArticle(news)}
                    />
                  ))}
              </div>
            </div>
          </div>
          {selectedNews && (
            <React.Fragment>
              <div className={cssClass(style.container)}>
                <div className={cssClass(style.project__return, style.return)}>
                  <span className={cssClass(style.return__link)} onClick={() => setSelectedNews(null)}>
                    <TokenIcons name={'arrowLeft'} />
                    <span className={cssClass(style.return__text)}>{t('TokenAllUpdates')}</span>
                  </span>
                </div>
              </div>
              <NewsArticlesMoreDetail
                t={t}
                index={selectedNewsIndex + 1}
                selectedNews={selectedNews}
                onLikeUpdateForNews={handleLikeUpdateForNews}
                newsArticleCommentsCount={
                  newsArticlesCommentsCount.find((item) => item.newsId === selectedNews?.id)?.count
                }
                onUpdateCommentsCountForNews={handleUpdateCommentsCountForNews}
                isAuthor={isAuthor}
                isEditNews={() => processNewsArticle(selectedNews)}
              />
            </React.Fragment>
          )}
        </div>
      )}
      {selectTab === Tabs.CreateOrEditNewsArticle && (
        <CreateOrEditNewsArticle
          t={t}
          currencyListingCode={code}
          editNewsArticle={editNewsArticle}
          onUpdate={() => {
            setEditNewsArticle(null)
            fetchNewsArticles()
            setSelectTab(Tabs.NewsArticles)
          }}
          onBack={() => {
            setEditNewsArticle(null)
            setSelectTab(Tabs.NewsArticles)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default NewsArticlesTab
