import style from '../style/TokenPage.module.scss'
import { cssClass } from 'helpers/helper'
import TokenIcons from '../TokenData/TokenIcons'
import { UpdateLikeForNews, FormatedDate, LikesCount } from '../TokenData/TokenData'
import React, { useEffect, useState } from 'react'
import api from 'api'
import { reaction } from 'mobx'
import QuillEditor from 'pages/common/components/TextEditor/QuillEditor'
import QuillHTMLOutput from 'pages/common/components/TextEditor/QuillHTMLOutput'

const NewsArticle = ({
  t,
  index,
  newsArticle,
  setSelectedNews,
  onLikeUpdateForNews,
  newsArticleCommentsCount,
  isAuthor,
  isEditNews,
}) => {
  const [news, setNews] = useState(null)
  const [newsLikes, setNewsLikes] = useState(null)
  const [commentsCount, setCommentsCount] = useState(null)

  useEffect(() => {
    if (!newsArticle || newsArticle?.newsVersions?.length === 0) return
    const lastNews = newsArticle.newsVersions[newsArticle.newsVersions.length - 1]
    setNews(lastNews)
    setNewsLikes(newsArticle.newsLikes)
  }, [newsArticle])

  useEffect(() => {
    if (!newsArticleCommentsCount) return
    setCommentsCount(newsArticleCommentsCount)
  }, [newsArticleCommentsCount])

  return (
    <React.Fragment>
      <div className={cssClass(style.articles__item, style.article)}>
        <div className={cssClass(style.article__header)}>
          <div className={cssClass(style.article__tag, style.tag)}>
            <span className={cssClass(style.tag__text)}>
              {t('TokenUpdate')} # {index}
            </span>
            {isAuthor && (
              <button className={cssClass(style.edit__btn)} onClick={isEditNews}>
                <TokenIcons name={'editIco'} />
              </button>
            )}
          </div>

          <div className={cssClass(style.article__date, style.date)}>
            <span className={cssClass(style.date__text)}>{FormatedDate(news?.dateCreated)}</span>
          </div>
        </div>
        <div className={cssClass(style.article__main)}>
          <div className={cssClass(style.wysiwyg)}>
            <h2>{news?.title || ''}</h2>
            <h3>{news?.preview || ''}</h3>
            <QuillHTMLOutput html={news?.body || ''} />
          </div>
        </div>
        <div className={cssClass(style.article__footer)}>
          <div className={cssClass(style.article__data, style.data)}>
            <ul className={cssClass(style.data__list)}>
              <button className={cssClass(style.data__item)} onClick={() => onLikeUpdateForNews(news?.newsItemId)}>
                <TokenIcons name={'favorite'} />
                <span className={cssClass(style.data__text)}>{LikesCount(newsLikes)}</span>
              </button>
              <li className={cssClass(style.data__item)}>
                <TokenIcons name={'comment'} />
                <span className={cssClass(style.data__text)}>{commentsCount || ''}</span>
              </li>
            </ul>
          </div>

          <div className={cssClass(style.article__detail)}>
            <span
              className={cssClass(style.btn, style.btn_secondary, style.btn_transparent)}
              onClick={() => {
                setSelectedNews(newsArticle)
              }}
            >
              <span className={cssClass(style.btn__text)}>{t('TokenReadMore')}</span>
              <TokenIcons name={'arrowTop'} />
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsArticle
