import React, { useEffect, useState } from 'react'
import { cssClass } from 'helpers/helper'
import style from '../style/TokenPage.module.scss'
import TokenIcons from '../TokenData/TokenIcons'
import api from 'api'
import { GetTokenUSDTPair, SocialIconNames } from '../TokenData/TokenData'

export default function ProjectInformation({ t, currencyListing, exchangeInstruments }) {
  const [countOfTokenOwners, setCountOfTokenOwners] = useState(null)
  const [totalSupply, setTotalSupply] = useState(null)
  const [tokenUSDTPair, setTokenUSDTPair] = useState(null)

  const [socialLinksAndIconNames, setSocialLinksAndIconNames] = useState([])

  function getHostName(url) {
    let protocolRegex = /^(.*?:\/\/)/
    const match = url.match(protocolRegex)
    if (match) {
      protocolRegex = /^(https?:\/\/)/i
      const withoutProtocol = url.replace(protocolRegex, '')
      const hostWithPath = withoutProtocol.split('/')[0]
      const hostWithoutWww = hostWithPath.replace(/^www\./i, '')
      const hostName = hostWithoutWww.split('.')[0]

      return hostName
    }
    const hostName = url.split('/')[0].split('.')[0]
    return hostName
  }

  useEffect(() => {
    if (!currencyListing) return

    api.fetchCountOfTokenOwners(currencyListing.currencyCode).then((data) => {
      setCountOfTokenOwners(data)
    })

    api.fetchTotalSupply(currencyListing.currencyCode).then((data) => {
      setTotalSupply(data)
    })

    for (let i = 1; i <= 4; i++) {
      const socialLink = currencyListing[`socialLink${i}`]
      if (socialLink) {
        const hostName = getHostName(socialLink)
        const iconName = SocialIconNames[hostName]
        if (!iconName) {
          // socialLinksAndIconNames.push({ link: socialLink, iconName: 'defaultSocialIco' })
          continue
        }
        socialLinksAndIconNames.push({ link: socialLink, iconName: iconName })
      }
    }
  }, [currencyListing])

  useEffect(() => {
    if (!exchangeInstruments) return null
    const tokenUSDTPair = GetTokenUSDTPair(exchangeInstruments)
    setTokenUSDTPair(tokenUSDTPair)
  }, [exchangeInstruments])

  return (
    <div className={cssClass(style.sidebar__group, style.group)}>
      <div className={cssClass(style.group__header)}>
        <span className={cssClass(style.group__title)}>{t('TokenGeneralProjectInformation')}:</span>
        <span className={cssClass(style.group__subtitle)}>{currencyListing?.tokenName}</span>
      </div>
      <div className={cssClass(style.group__main)}>
        <ul className={cssClass(style.group__list)}>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenProjectCapitalization')}:</span>
            <span className={cssClass(style.group__subtitle)}>${totalSupply * tokenUSDTPair?.price || '---'}</span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenOwners')}:</span>
            <span className={cssClass(style.group__subtitle)}>{countOfTokenOwners}</span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenTotalSupply')}:</span>
            <span className={cssClass(style.group__subtitle)}>{totalSupply}</span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenPriceCostOf1Token')}:</span>
            <span className={cssClass(style.group__subtitle)}>
              ${parseFloat(tokenUSDTPair?.price?.toFixed(8)) || '---'}
            </span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenProjectCategory')}:</span>
            <span className={cssClass(style.group__subtitle)}>{currencyListing?.category}</span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenProjectLocation')}:</span>
            <span className={cssClass(style.group__subtitle)}>{currencyListing?.location}</span>
          </li>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenProjectSocialNetworks')}:</span>
            <span className={cssClass(style.group__subtitle)}>{''}</span>
            <div className={cssClass(style.socials)}>
              <ul className={cssClass(style.socials__list)}>
                {socialLinksAndIconNames.length !== 0 &&
                  socialLinksAndIconNames.map((item, index) => (
                    <li key={index} className={cssClass(style.socials__item)}>
                      <a
                        className={cssClass(style.socials__link)}
                        href={item.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <TokenIcons name={item.iconName} />
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
