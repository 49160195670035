import { ChatFooter } from './ChatFooter'
import ChatHeader from './ChatHeader'
import moment from 'moment/moment'
import ProgressIcons from '../ProgressIcons'
import React, { useEffect, useState, useRef } from 'react'
import { ChatMessage } from './UserMessages'
import { SystemMessageWarning, SystemMessageMegaphone } from './SystemMessages'
import api from 'api'

const ProcessChat = ({ t, deal, needUpdateMessages, setNeedUpdateMessages }) => {
  const chatContainerRef = useRef(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const [messagesWithMetadataState, setMessagesWithMetadataState] = useState([])
  const [chatStatus, setChatStatus] = useState(false)
  const formattedDate = (date) => {
    return moment(date).format('DD/MM/YY')
  }

  const setMessagesWasRead = async (chatMessageWithMetadataState) => {
    if (!chatMessageWithMetadataState.length) return
    const userId = deal.isDealCreator ? deal.order.userId : deal.dealUserId
    const unreadMessages = chatMessageWithMetadataState
      .filter((message) => !message.wasRead && message.userId === userId)
      .map((m) => m.id)

    if (!unreadMessages.length) return
    try {
      await api.setP2PMessagesWasRead(unreadMessages)
    } catch (error) {
      console.error('Error when sending a message read report', error)
    }
  }

  const fetchChatMessages = async (dealId, ignore) => {
    try {
      const newData = await api.fetchP2PMessages(dealId)
      if (ignore) return
      setMessagesWithMetadataState((previousMessages) => {
        const newMessagesMap = new Map(newData.map((message) => [message.id, message]))

        const updatedPreviousMessages = previousMessages.map((prevMessage) => {
          const newMessage = newMessagesMap.get(prevMessage.id)
          if (newMessage && newMessage.wasRead !== prevMessage.wasRead) {
            return newMessage
          }
          return prevMessage
        })

        const addedNewMessages = newData.filter(
          (newMessage) => !previousMessages.some((prevMessage) => prevMessage.id === newMessage.id),
        )
        return [...updatedPreviousMessages, ...addedNewMessages]
      })
    } catch (error) {
      if (ignore) return
      console.error('Error loading chat message', error)
    }
  }

  const checkScrollBottom = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current
    return scrollTop + clientHeight === scrollHeight
  }

  const handleScroll = () => {
    setIsAtBottom(checkScrollBottom())
  }

  const scrollToBottom = () => {
    if (isAtBottom) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  if (!messagesWithMetadataState) return null

  const groupedMessages = messagesWithMetadataState.reduce((acc, message) => {
    const date = formattedDate(message.dateCreated)
    if (!acc[date]) {
      acc[date] = []
    }

    acc[date].push(message)
    return acc
  }, {})

  useEffect(() => {
    let ignore = false
    if (!messagesWithMetadataState) return
    scrollToBottom()
    setMessagesWasRead(messagesWithMetadataState, ignore)
  }, [messagesWithMetadataState])

  useEffect(() => {
    const container = chatContainerRef.current
    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!deal) return
    fetchChatMessages(deal.id)
  }, [])

  useEffect(() => {
    if (!needUpdateMessages) return
    fetchChatMessages(deal.id)
    setNeedUpdateMessages(false)
  }, [needUpdateMessages])

  useEffect(() => {
    let ignore = false
    const fetchP2PMetadataChatFile = async (fileId, ignore) => {
      try {
        const metadata = await api.fetchP2PMetadataChatFile(fileId)
        if (ignore) return null
        return metadata
      } catch (error) {
        console.error('Error loading chat message', error)
        return null
      }
    }

    const chatFileIds = messagesWithMetadataState.filter((m) => m.chatFileId && !m.chatFile).map((m) => m.chatFileId)

    const fetchFilesAndUpdateMessages = async (ignore) => {
      if (ignore) return

      const fileMetadatas = await Promise.all(chatFileIds.map((id) => fetchP2PMetadataChatFile(id, ignore)))
      setMessagesWithMetadataState((previousMessages) => {
        let hasUpdates = false
        const updatedMessages = previousMessages.map((message) => {
          if (message.chatFileId && !message.chatFile) {
            const metadata = fileMetadatas.find((md) => md && md.id === message.chatFileId)
            if (metadata) {
              hasUpdates = true
              return {
                ...message,
                chatFile: {
                  id: metadata.id,
                  fileName: metadata.fileName,
                  extension: metadata.extension,
                  fileSize: metadata.fileSize,
                },
              }
            }
          }
          return message
        })

        return hasUpdates ? updatedMessages : previousMessages
      })
    }

    if (chatFileIds.length > 0) {
      fetchFilesAndUpdateMessages(ignore)
    }
    return () => {
      ignore = true
    }
  }, [messagesWithMetadataState.map((m) => m.chatFileId).join(',')])

  return (
    <React.Fragment>
      {chatStatus}
      <div className={`layout__chat chat ${chatStatus ? 'layout__chat_active' : ''}`}>
        <div className='chat__preview' onClick={() => setChatStatus(!chatStatus)}>
          <ProgressIcons name='sellerIco' />
          <span className='chat__text'>{t('ContactTheSeller')}</span>
        </div>
        <div className='chat__layout'>
          <ChatHeader t={t} deal={deal} chatMessageWithMetadataState={messagesWithMetadataState} />
          <div className='chat__main'>
            <div className='chat__main' ref={chatContainerRef} onScroll={handleScroll}>
              {Object.keys(groupedMessages).map((date) => (
                <React.Fragment key={date}>
                  <div className='chat__day day'>
                    <div className='day__header'>
                      <span className='day__title'>{date}</span>
                    </div>
                    <div className='day__main'>
                      <div className='chat__list'>
                        {groupedMessages[date].map((message) => (
                          <div key={message.id}>
                            <SystemMessageMegaphone t={t} chatMessage={message} deal={deal} />
                            <SystemMessageWarning t={t} chatMessage={message} deal={deal} />
                            <ChatMessage t={t} chatMessage={message} deal={deal} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <ChatFooter deal={deal} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProcessChat
