import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Tooltip from 'components/form/Tooltip/Tooltip'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import NavigationButtons from '../NavigationButtons'
import SelectPaymentMethodsModal from '../Modals/SelectPaymentMethodsModal'
import PostingIcon from './PostingIcons'
import plusImg from './images/plus.svg'
import closeImg from './images/close.svg'
import api from '../../../../api'
import CommonModals from '../../Progress/Modals/CommonsMadals'
import useModalState from '../../Progress/Modals/useModalState'

export function Stage2({ onStage2DataSubmit, onButtonNextDisabled, stageData }) {
  const { t } = useTranslation()
  const { modals, openModal, closeModal } = useModalState()
  const {
    buySellTabsIndex,
    commissionPercent,
    userSelectedTradeRate,
    selectedOtcAsset,
    selectedExchangeAsset,
    balanceOfSelectedOtcAsset,
    balanceOfSelectedExchangeAsset,
  } = stageData

  const [selectedPaymentRequisites, setSelectedPaymentRequisites] = useState(stageData.selectedPaymentRequisites || [])
  const [limitMin, setLimitMin] = useState(stageData.limitMin)
  const [limitMax, setLimitMax] = useState(stageData.amountOfSelectedExchangeAsset)

  const [selectedPaymentDuration, setSelectedPaymentDuration] = useState(stageData.selectedPaymentDuration)

  const [amountOfSelectedExchangeAsset, setAmountOfSelectedExchangeAsset] = useState(
    stageData.amountOfSelectedExchangeAsset,
  )
  const [amountOfSelectedOtcAsset, setAmountOfSelectedOtcAsset] = useState(stageData.amountOfSelectedOtcAsset)
  const [commissionAmount, setCommissionAmount] = useState(stageData.commissionAmount)

  const durationList = [15, 30, 60]
  const [paymentRequisites, setPaymentRequisites] = useState([])
  const [isPaymentMethodsModalOpen, setIsPaymentMethodsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isInputValueChanged, setIsInputValueChanged] = useState(false)

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const handleOpenPaymentMethods = async () => {
    setIsLoading(true)
    try {
      const data = await api.fetchP2PRequisites()
      setPaymentRequisites(data || [])
    } catch (error) {
      console.error('Error fetching requisites:', error)
    } finally {
      setIsLoading(false)
    }
    setIsPaymentMethodsModalOpen(true)
  }
  const handleRemoveMethod = (methodId) => {
    setSelectedPaymentRequisites((currentRequisites) =>
      currentRequisites.map((method) => (method.id === methodId ? { ...method, selected: false } : method)),
    )
  }
  const checkNextButtonDisabled = () => {
    return (
      parseFloat(limitMin) === 0 ||
      parseFloat(limitMax) === 0 ||
      parseFloat(amountOfSelectedExchangeAsset) === 0 ||
      parseFloat(amountOfSelectedOtcAsset) === 0 ||
      !selectedPaymentRequisites ||
      selectedPaymentRequisites.length === 0 ||
      !selectedPaymentRequisites.some((item) => item.selected === true) ||
      parseFloat(limitMax) < parseFloat(limitMin) ||
      parseFloat(limitMax) > parseFloat(amountOfSelectedExchangeAsset)
    )
  }
  const formDataToSubmit = () => {
    const dataToSubmit = {
      // currentStageIndex,
      // paymentRequisites,
      selectedPaymentRequisites: selectedPaymentRequisites.filter((pr) => pr.selected),
      limitMin,
      limitMax,
      selectedPaymentDuration,
      amountOfSelectedExchangeAsset,
      amountOfSelectedOtcAsset,
      commissionAmount,
      commissionPercent,
      // selectedExchangeAsset,
      // userSelectedTradeRate,
    }
    onStage2DataSubmit(dataToSubmit)
  }

  useEffect(() => {
    formDataToSubmit()
  }, [stageData.currentStageIndex, selectedPaymentRequisites, selectedPaymentDuration])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(checkNextButtonDisabled())
  }, [selectedPaymentRequisites, limitMin, limitMax, amountOfSelectedExchangeAsset, amountOfSelectedOtcAsset])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className='wrapper'>
          <div className='group group_secondary'>
            <div className='formular'>
              <div className='row'>
                <div className='col col_5 col_desktop-6 col_tab-8 col_mob-12'>
                  <div className='form-items nowrap'>
                    <div className='form-item w-100'>
                      {buySellTabsIndex === 0 && <span className='form-item__title'>{t('ReceiveActive')}</span>}
                      {buySellTabsIndex === 1 && <span className='form-item__title'>{t('GivesActive')}</span>}
                      <div className='form-item__header'>
                        <Tooltip
                          title={'Available'}
                          info={[t('SpecifyHowMuch', { currency: selectedExchangeAsset })]}
                        />
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={selectedExchangeAsset}
                            value={amountOfSelectedExchangeAsset}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              setAmountOfSelectedExchangeAsset(value === '' ? '0' : value)
                              const totalAmount = (value * userSelectedTradeRate).toFixed(2)
                              setAmountOfSelectedOtcAsset(totalAmount)
                              const maxLimit =
                                limitMax > amountOfSelectedExchangeAsset ? amountOfSelectedExchangeAsset : limitMax
                              setLimitMax(maxLimit)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_before'>
                            <span className='data__text'>{t('Balance')}:</span>
                          </div>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {balanceOfSelectedExchangeAsset} {selectedExchangeAsset}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item p-0'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <div className='form-item__ico'>
                            <PostingIcon name='ico01' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item w-100'>
                      {buySellTabsIndex === 0 && <span className='form-item__title'>{t('GivesFiat')}</span>}
                      {buySellTabsIndex === 1 && <span className='form-item__title'>{t('ReceiveFiat')}</span>}
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={selectedOtcAsset}
                            value={amountOfSelectedOtcAsset}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              setAmountOfSelectedOtcAsset(value)
                              const amount = value === '0' ? '0' : (value / userSelectedTradeRate).toFixed(2)
                              setAmountOfSelectedExchangeAsset(amount)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {balanceOfSelectedOtcAsset} {selectedOtcAsset}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_2 col_desktop-3 col_tab-6'>
                  <div className='form-item w-100'>
                    <div className='form-item__header'>
                      <div className='form-item__header'></div>
                      <span className='form-item__title'>
                        {t('Commission')} {commissionPercent}%
                      </span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Input
                          after={selectedExchangeAsset}
                          value={commissionAmount}
                          disabled={true}
                          inputCallback={() => setCommissionAmount}
                          addClasses={['w-100']}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col col_5 col_desktop-6 col_tab-8 col_mob-12'>
                  <div className='form-items nowrap'>
                    <div className='form-item w-100'>
                      <div className='form-item__header'>
                        <Tooltip title={'Limits'} info={[t('SpecifyOnTheLeft'), t('SpecifyOnTheRight')]} />
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={'min ' + selectedExchangeAsset}
                            value={limitMin}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const numericValue = parseFloat(value)
                              const numericLimitMax = parseFloat(limitMax)
                              const limit = numericValue <= numericLimitMax ? numericValue : numericLimitMax
                              setLimitMin(limit)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-item p-0'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <div className='form-item__ico'>
                            <PostingIcon name='waveIco' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item w-100'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={'max ' + amountOfSelectedExchangeAsset + ' ' + selectedExchangeAsset}
                            value={limitMax}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const allowedValue =
                                parseFloat(value) > parseFloat(amountOfSelectedExchangeAsset)
                                  ? amountOfSelectedExchangeAsset
                                  : value
                              setLimitMax(allowedValue)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='group'>
            <div className='formular'>
              <div className='row'>
                <div className='col col_3 col_tab-4 col_mob-7'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('PaymentMethods')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='wysiwyg'>
                        <p>
                          <strong>{t('ChooseUpTo5Ways')}</strong>
                        </p>
                      </div>
                      <div className='form-item__data'>
                        {selectedPaymentRequisites &&
                          selectedPaymentRequisites
                            .filter((method) => method.selected)
                            .map((method) => (
                              <span className='form-item__title' key={method.id}>
                                {method.paymentSystem.name} ({method.paymentMethodCurrencyCode})
                                <span
                                  className='payment-method__remove'
                                  onClick={() => handleRemoveMethod(method.id)}
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                >
                                  <img className='form-item__ico' src={closeImg} alt='✖' />
                                </span>
                              </span>
                            ))}
                        <button className='btn' disabled={isLoading} onClick={() => handleOpenPaymentMethods()}>
                          {isLoading ? (
                            <div>Loading...</div>
                          ) : selectedPaymentRequisites.length === 0 ? (
                            <span className='btn__text'>{t('PaymentMethods')}</span>
                          ) : (
                            <img className='form-item__ico' src={plusImg} alt='+' />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col col_2 col_tab-4 col_mob-7'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('PaymentTerm')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Select
                          list={durationList.map((key) => t('P2PMins', { mins: key }))}
                          value={t('P2PMins', { mins: selectedPaymentDuration })}
                          addClasses={['w-100']}
                          filter={true}
                          multiple={false}
                          selectCallback={(value) => {
                            const mins = parseInt(value, 10)
                            if (!isNaN(mins)) {
                              setSelectedPaymentDuration(mins)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectPaymentMethodsModal
        opened={isPaymentMethodsModalOpen}
        setOpened={setIsPaymentMethodsModalOpen}
        paymentRequisites={paymentRequisites}
        selectedPaymentRequisites={selectedPaymentRequisites}
        onPaymentMethodChange={setSelectedPaymentRequisites}
      />
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        onConfirmCommonModals={(actionType, payload) => {
          onProcessBottomActions(actionType, payload)
        }}
      />
    </React.Fragment>
  )
}
