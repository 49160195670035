import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'
import defaultImageLogoSmall from '../img/defaultLogoSmall.png'

import {
  ExchangeCoinName,
  TokenMarketType,
  SortPairs,
  SortPairCriteria,
  DaysFromFirstDeal,
} from '../TokenData/TokenData'
import TokenIcons from '../TokenData/TokenIcons'
import api from 'api'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

const First_N_Pair = 5
const ExchangeCoinPlaceInTopIndex = 5

export default function TokensMarketOverview({ t, enrichedTradablePairs, navigationFunctions }) {
  const [selectedTokenTab, setSelectedTokenTab] = useState(TokenMarketType.Popular)
  const [marketsData, setMarketsData] = useState([
    { type: TokenMarketType.Popular, pairs: [] },
    { type: TokenMarketType.Hot, pairs: [] },
    { type: TokenMarketType.NewListing, pairs: [] },
  ])

  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }

  const updateMarketData = (marketDataType, newPairs) => {
    setMarketsData((prevMarketsType) =>
      prevMarketsType.map((market) => (market.type === marketDataType ? { ...market, pairs: newPairs } : market)),
    )
  }

  const processTopPairs = () => {
    const allPairsExcludeExchangeTokenPair = enrichedTradablePairs.filter((pair) => {
      const [firstCurrency, secondCurrency] = pair.currencyPair.code.split('_')
      return firstCurrency !== ExchangeCoinName && secondCurrency !== ExchangeCoinName
    })
    const exchangeTokenPair = enrichedTradablePairs.filter((pair) => {
      const [firstCurrency, secondCurrency] = pair.currencyPair.code.split('_')
      return firstCurrency === ExchangeCoinName || secondCurrency === ExchangeCoinName
    })

    const sortPairByLastTradedPrice = SortPairs(
      allPairsExcludeExchangeTokenPair,
      SortPairCriteria.Volume24H,
      First_N_Pair,
    )

    const insertExchangeTokenPair = (sortedPairs, exchangeTokenPair, insertIndex, maxLength) => {
      const result = [...sortedPairs.slice(0, insertIndex), ...exchangeTokenPair, ...sortedPairs.slice(insertIndex)]
      return result.slice(0, maxLength)
    }

    const finalSortedPairsForTop = insertExchangeTokenPair(
      sortPairByLastTradedPrice,
      exchangeTokenPair,
      ExchangeCoinPlaceInTopIndex,
      First_N_Pair,
    )

    updateMarketData(TokenMarketType.Popular, finalSortedPairsForTop)
  }

  const processNewListings = () => {
    const newListings = enrichedTradablePairs.filter((pair) => {
      const firstTradeDate = new Date(pair.firstTradeDate)
      const fourteenDaysAgo = new Date(Date.now() - DaysFromFirstDeal * 24 * 60 * 60 * 1000)
      return firstTradeDate >= fourteenDaysAgo
    })

    const sortedNewListingsPair = SortPairs(newListings, SortPairCriteria.Volume24H, First_N_Pair)

    updateMarketData(TokenMarketType.NewListing, sortedNewListingsPair)
  }

  const processHot = () => {
    const displayOrderedPairs = enrichedTradablePairs.filter((pair) => pair.marketPageDisplayOrder !== 0)
    const sortedHotPair = SortPairs(displayOrderedPairs, SortPairCriteria.MarketPageDisplayOrder, First_N_Pair, 'asc')

    updateMarketData(TokenMarketType.Hot, sortedHotPair)
  }

  useEffect(() => {
    if (!enrichedTradablePairs || enrichedTradablePairs.length === 0) return

    processTopPairs()
    processNewListings()
    processHot()
  }, [enrichedTradablePairs])

  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__main'>
            <div className='layout__investors investors'>
              <div className='investors__layout'>
                <div className='investors__header'>
                  <h2 className='investors__title'>{t('StartEarning')}</h2>
                </div>
                <div className='investors__main'>
                  <span className='investors__text'>{t('TradeCoinsCreated')}</span>
                </div>
                <div className='investors__footer'>
                  <a className='btn' onClick={() => goToTokensMarketsPage()}>
                    <span className='btn__text'>{t('StartEarningMoney')}</span>
                  </a>
                </div>
              </div>

              <div className='investors__tabs tabs layout__box'>
                <div className='tabs__header'>
                  <ul className='tabs__list'>
                    <li
                      className={`tabs__item ${
                        selectedTokenTab === TokenMarketType.Popular ? 'tabs__item_active' : ''
                      }`}
                      onClick={() => setSelectedTokenTab(TokenMarketType.Popular)}
                    >
                      <span className='tabs__title'>{TokenMarketType.Popular}</span>
                    </li>
                    <li
                      className={`tabs__item ${
                        selectedTokenTab === TokenMarketType.NewListing ? 'tabs__item_active' : ''
                      }`}
                      onClick={() => setSelectedTokenTab(TokenMarketType.NewListing)}
                    >
                      <span className='tabs__title'>{TokenMarketType.NewListing}</span>
                    </li>
                    <li
                      className={`tabs__item ${selectedTokenTab === TokenMarketType.Hot ? 'tabs__item_active' : ''}`}
                      onClick={() => setSelectedTokenTab(TokenMarketType.Hot)}
                    >
                      <span className='tabs__title'>{TokenMarketType.Hot}</span>
                    </li>
                  </ul>
                </div>
                <div className='tabs__main'>
                  <div className='tabs__list'>
                    <div className='tabs__item'>
                      <div className='layout__markets markets'>
                        <div className='markets__section'>
                          <div className='markets__main'>
                            <div className='markets__item markets__item_secondary market'>
                              <div className='market__main'>
                                <div className='market__table table'>
                                  <div className='table__header'>
                                    <ul className='table__list table__list_tertiary'>
                                      <li className='table__item'>
                                        <span className='table__title table__title_secondary'>
                                          {t('TokenTrandingPairs')}
                                        </span>
                                      </li>
                                      <li className='table__item'>
                                        <span className='table__title table__title_secondary'>
                                          <span className='table__subtitle'>{t('TokenLastTraidedPrice')}</span>
                                        </span>
                                      </li>
                                      <li className='table__item'>
                                        <span className='table__title table__title_secondary'>
                                          {t('Token24HChange')}
                                        </span>
                                      </li>
                                      <li className='table__item'>
                                        <span className='table__title table__title_secondary'>{t('TokenTrade')}</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='table__main'>
                                    {marketsData
                                      .filter((market) => market.type === selectedTokenTab)
                                      .map((market) => (
                                        <ul key={market.type} className='table__list table__list_tertiary'>
                                          {market.pairs.map((pair, index) => (
                                            <React.Fragment key={`${pair.currencyCode}-${market.type}-${index}`}>
                                              <li
                                                className='table__item'
                                                onClick={() => goToTokensPage(pair.currencyCode)}
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <span className='table__ico'>
                                                  <CurrencyIcon size={70} currencyCode={pair.currencyCode} />
                                                </span>
                                                <span className='table__text'>{pair.currencyCode}</span>
                                              </li>
                                              <li className='table__item'>
                                                <span className='table__text'>{pair.price}</span>
                                              </li>
                                              <li className='table__item'>
                                                <span className='table__text table__text_secondary'>
                                                  {pair.priceChangePercentage24h}%
                                                </span>
                                              </li>
                                              <li className='table__item'>
                                                <button
                                                  className='btn btn_transparent btn_tiny'
                                                  onClick={() => goToTokensTradePage(pair.currencyPair.code)}
                                                >
                                                  <span className='btn__text'>{t('TokenTrade')}</span>
                                                </button>
                                              </li>
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='tabs__footer'>
                  <button className='tabs__link link' onClick={() => goToTokensMarketsPage()}>
                    <span className='link__text'>{t('TokenSeeAll350Tokens')}</span>
                    <TokenIcons name={'chevronRightIco'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
