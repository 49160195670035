import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../../secondaryLayout/scss/main.sass'
import TokenIcons from '../../TokenData/TokenIcons.js'
import QuillEditor from 'pages/common/components/TextEditor/QuillEditor'
import 'pages/common/components/TextEditor/quill-wrapper-styles.css'
import TokenInput from 'pages/tokens/TokenData/TokenInput'

export default function AboutProject({ onInputChange, tokenData }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div id='tokens__about' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>{t('TokenAboutProject')}:</span>
        </div>
        <div className='group__main'>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input']}
                  value={tokenData.title || ''}
                  // validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                  inputCallback={(value) => {
                    onInputChange('title', value)
                  }}
                  placeholder={'Title'}
                  onBlur={(e) => {
                    onInputChange('title', e.target.value.trim())
                  }}
                />
                <TokenIcons name={'editIco'} />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheHeaderOfYourProject')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input']}
                  value={tokenData.subtitle || ''}
                  // validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                  inputCallback={(value) => {
                    onInputChange('subtitle', value)
                  }}
                  placeholder={'Subtitle'}
                  onBlur={(e) => {
                    onInputChange('subtitle', e.target.value.trim())
                  }}
                />
                <TokenIcons name={'editIco'} />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheSubtitleOfYourProject')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <QuillEditor
                value={tokenData.content || ''}
                placeholder={'Description'}
                onChange={(value) => onInputChange('content', value.trim())}
                classNames={
                  ' form__field_secondary quill-wrapper form__input form__input_textarea quill-container quill-container-fixed-height'
                }
              />
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheDescritionOfYourProject')}</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
